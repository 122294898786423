import React, { useState, useEffect } from 'react'

import { GetDemoExam, GetExam, SetFinishedDemoExam, SetFinishedExam, GetExamTime, GetDemoTime, SetRemainingDemoTime, SetRemainingExamTime } from '../../utilities/BL/Settings'

import { Step } from './Step'
import { FinishMessage } from './FinishMessage'
import { Timer } from './Timer'

const Evaluator = ({ beforeView, isDemo }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [examData, setExamData] = useState(null)
    const [selectedAnswers, setSelectedAnswers] = useState({}) // Estado para almacenar las respuestas seleccionadas
    const [activeComponent, setActiveComponent] = useState('Questions')

    const [timeRemaining, setTimeRemaining] = useState(0) // Estado para el tiempo restante
    const [isRunning, setIsRunning] = useState(false) // Estado para controlar si el temporizador está corriendo
    const [showTimeUpMessage, setShowTimeUpMessage] = useState(false) // Nuevo estado para controlar el mensaje
    const [hasTimerStarted, setHasTimerStarted] = useState(false) // Nuevo estado para controlar si el temporizador ha comenzado
    const [isConfirming, setIsConfirming] = useState(false) // Nuevo estado
    const [showConfirmModal, setShowConfirmModal] = useState(false) // Nuevo estado para el modal de confirmación

    const [selectedOption, setSelectedOption] = useState(null) // Nuevo estado para la opción seleccionada




    /**
     * Funciones que se ejecutan al cargar el componente
     */
    useEffect(() => {
        let examInfo = null
        if (isDemo) {
            examInfo = GetDemoExam()
        } else {
            examInfo = GetExam()
        }
        setExamData(examInfo)


        // Obtener el tiempo inicial y establecerlo en el estado
        const initialTime = isDemo ? GetDemoTime() : GetExamTime()
        setTimeRemaining(parseInt(initialTime, 10) || 0)
        setIsRunning(true) // Iniciar el temporizador al cargar la pregunta
    }, []) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar

    /**
     * Funciones que se ejecutan cuando hay tiempo y está corriendo
     */
    useEffect(() => {
        let intervalId
    
        if (isRunning && timeRemaining > 0) {
          intervalId = setInterval(() => {
            setTimeRemaining((prevTime) => prevTime - 1)
          }, 1000)

          // Marcar que el temporizador ha comenzado después del primer intervalo
            if (!hasTimerStarted) {
                setHasTimerStarted(true)
            }
        } else if (timeRemaining === 0 && hasTimerStarted) { // Verificar si el tiempo se agotó DESPUÉS de que el temporizador haya comenzado
          clearInterval(intervalId)
          handleTimeUp() // Manejar el caso en que el tiempo se agote
        }
    
        return () => clearInterval(intervalId)
      }, [isRunning, timeRemaining])




    /**
     * Funciones para avanzar de pregunta y finalizar examen
     */
    const Common = () => {
        try {
            // Detener el temporizador y registrar el tiempo SOLO si el usuario confirma
            handleStopTimer()

            if (currentQuestionIndex < examData.questions.length - 1) {
                setCurrentQuestionIndex(currentQuestionIndex + 1)
                setIsRunning(true) // Retomar el temporizador al pasar a la siguiente pregunta
            } else {
                //Mostrar el componente FinishMessage
                setActiveComponent('FinishMessage')

                if (isDemo) {
                    SetFinishedDemoExam()
                } else {
                    SetFinishedExam()
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    /**
     * Función que se ejecuta cuando se hace clic en el botón Siguiente
     * @returns boolean
     */
    const handleNextClick = () => {
        //Verificar si se ha seleccionado una respuesta para la pregunta actual
        if (!selectedAnswers[currentQuestionIndex]) {
            // setIsConfirming(true) // Marcar que se está mostrando la confirmación
            // //Preguntar si desea avanzar a pesar de no haber seleccionado una opción
            // if (window.confirm('¿Estás seguro que deseas avanzar sin elegir alguna opción en esta pregunta?') == false) {
            //     setIsConfirming(false) // Restablecer isConfirming si el usuario cancela
            //     return // Detener la ejecución si no hay respuesta seleccionada y el usuario cancela
            // }
            // setIsConfirming(false) // Restablecer isConfirming si el usuario confirma
            setShowConfirmModal(true) // Mostrar el modal de confirmación
            return // Detener la ejecución para esperar la respuesta del usuario en el modal
        }

        Common()
    }

    /**
     * Función que se ejecuta cuando se confirma el modal para avanzar de pregunta
     * @param {boolean} confirmed 
     */
    const handleConfirm = (confirmed) => {
        setShowConfirmModal(false) // Ocultar el modal de confirmación
        if (confirmed) { Common() }
    }

    /**
     * Función que se ejecuta cuando se detiene el temporizador
     */
    const handleStopTimer = () => {
        setIsRunning(false)

        if (isDemo) {
          SetRemainingDemoTime(timeRemaining)
        } else {
          SetRemainingExamTime(timeRemaining)
        }
    }

    /**
     * Función que se ejecuta cuando el tiempo se agota
     */
    const handleTimeUp = () => {
        if (!isConfirming) { // Verificar si NO se está mostrando la confirmación
            // ... Lógica para manejar cuando el tiempo se agota (opcional)
            setShowTimeUpMessage(true)
            handleStopTimer()

            if (isDemo) {
                SetRemainingDemoTime(0) // Establecer el tiempo restante en 0
                SetFinishedDemoExam() // Marcar el examen como finalizado
            } else {
                SetRemainingExamTime(0)
                SetFinishedExam()
            }
        }
    }

    /**
     * Función que se ejecuta cuando se hace clic en el botón Ir al panel
     */
    const handleGoToFinishMessage = () => {
        setActiveComponent('FinishMessage')
    }
    
    /**
     * Función que se ejecuta cuando se cambia de opción en el Radio button
     */
    const handleOptionChange = (event) => {
        setSelectedAnswers({
          ...selectedAnswers,
          [currentQuestionIndex]: event.target.value 
        })
        
        setSelectedOption(event.target.value) // Actualizar la opción seleccionada
    }
    
    try {
        return (
            <div className="container Evaluator">
                <div className="Evaluator-Question">
                    {activeComponent === 'Questions' && examData && ( 
                        <>
                            <Step current={currentQuestionIndex + 1} total={examData.questions.length} />

                            <p>{examData.questions[currentQuestionIndex].question}</p>
                
                            {/* Renderizar los radio buttons */}
                            {examData.questions[currentQuestionIndex].options.map((option, index) => (
                                <label key={index} className={`Evaluator-Question-Label ${selectedOption === option ? 'Evaluator-Question-Label-Selected' : ''}`}>
                                    <input type="radio" id={`option-${currentQuestionIndex}-${index}`} name={`answer-${currentQuestionIndex}`} value={option} checked={selectedAnswers[currentQuestionIndex] === option} onChange={handleOptionChange} /> {option}
                                </label>
                            ))}
                
                            {/* Botón Next o Finish */}
                            <div className="Evaluator-Container">
                                {/* Mostrar el mensaje de tiempo agotado y el botón si es necesario */}
                                {showTimeUpMessage ? (
                                    <div>
                                        <p>Tiempo agotado</p>
                                        <button className={'Evaluator-Container-BtnNext'} onClick={handleGoToFinishMessage}>Ir al panel</button>
                                    </div>
                                ) : (
                                    <>
                                        {/* Mostrar el temporizador solo si no se ha agotado el tiempo */}
                                        <Timer timeRemaining={timeRemaining} />

                                        {/* Mostrar el botón "Next" o "Finish" solo si no se ha agotado el tiempo */}
                                        <button className={'Evaluator-Container-BtnNext'} onClick={handleNextClick}>
                                            {currentQuestionIndex === examData.questions.length - 1 ? 'Finalizar examen' : 'Siguiente pregunta'}
                                        </button>
                                    </>
                                )}
                            </div>

                            {/* Mostrar el modal de confirmación si es necesario */}
                            {showConfirmModal && (
                                <div className="Evaluator-Modal"> {/* O utiliza tu propio componente de modal */}
                                    <div className="Evaluator-Modal-Content">
                                        <p>¿Estás segur@ que deseas avanzar sin elegir alguna opción en esta pregunta?</p>

                                        <div className='Evaluator-Modal-Buttons'>
                                            <button type='button' className='Evaluator-Modal-Buttons-Success' onClick={() => handleConfirm(true)}>Sí</button>
                                            <button type='button' className='Evaluator-Modal-Buttons-Cancel' onClick={() => handleConfirm(false)}>No</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    {
                        /* Mostrar FinishMessage cuando sea necesario */
                        activeComponent === 'FinishMessage' && <FinishMessage beforeView={beforeView} isDemo={isDemo} />
                    }
                </div>
            </div>
        )
    } catch (error) {
        console.log(error)
    }
}

export { Evaluator };