const FinishMessage = ({ beforeView, isDemo }) => {
    return (
        <>
            <div className='FinishMessage'>
                <i className='bi bi-check-circle FinishMessage-Icon'></i>

                <h1 className="FinishMessage-Title">¡Felicidades!</h1>

                <p className="FinishMessage-Text">Haz finalizado tu examen {isDemo ? 'Demo' : ''} con éxito.</p>

                <button type="button" className="FinishMessage-Button" onClick={() => beforeView()}>Ir al panel</button>
            </div>
        </>
    )
}

export { FinishMessage };