import { DLGetSettings } from '../DL/Settings'

/**
 * Función para obtener las configuraciones de la plataforma
 * @param {string} language 
 * @param {boolean} isLoginPage 
 * @returns 
 */
const GetSettings = async (language = null, isLoginPage = false) => {
    try {
      const settings = await DLGetSettings(language, isLoginPage) // Esperar a que getSettings termine

      if (settings === null) {
        return null
      }
      
      return settings
    } catch (error) {
      console.log(error)
    }
}





const GetMenu = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine

    if (settings === null || (settings.menu === undefined || settings.menu === null)) {
      return null
    }

    return settings.menu
  } catch (error) {
    console.log(error)
  }
}





const GetMessages = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (settings === null || (settings.messages === undefined || settings.messages === null)) {
      return null
    }

    return settings.messages
  } catch (error) {
    console.log(error)
  }
}




/**
 * Función que obtiene el tema de las configuraciones del usuario
 * @returns array
 */
const GetTheme = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine

    if (settings === null || (settings.theme === undefined || settings.theme === null)) {
      return null
    }

    return settings.theme
  } catch (error) {
    console.log(error)
  }
}

/**
 * Función que establece el nuevo tema en las configuraciones del usuario
 * @param {string} $theme 
 * @returns boolean
 */
const SetTheme = ($theme) => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine

    if (settings === null || (settings.theme === undefined || settings.theme === null)) {
      return null
    }

    if ($theme === null || $theme === '' || ($theme.toLowerCase() !== 'dark' && $theme.toLowerCase() !== 'light')) {
      return false
    }

    settings.theme = $theme.toLowerCase()
    sessionStorage.setItem('settings',JSON.stringify(settings))
    return true
  } catch (error) {
    console.log(error)
  }
}





const GetDemoExam = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (settings === null || (settings.demo === undefined || settings.demo === null)) {
      return null
    }

    return settings.demo
  } catch (error) {
    console.log(error)
  }
}

const GetOptionFinishedOfDemoExam = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (settings === null || (settings.demo === undefined || settings.demo === null)) {
      return null
    }

    if (settings.demo.finished === undefined || settings.demo.finished === null) {
      return null
    }

    const Data = {
      "finished" : settings.demo.finished,
      "dueDate" : settings.demo.dueDate,
      "completionDate" : settings.demo.completionDate
    }

    return Data
  } catch (error) {
    console.log(error)
  }
}

/**
 * Función para establecer el examen demo como finalizado
 * @returns boolean
 */
const SetFinishedDemoExam = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (settings === null || (settings.demo === undefined || settings.demo === null)) {
      return null
    }

    settings.demo.finished = true
    //Agregar fecha de completado
    sessionStorage.setItem('settings',JSON.stringify(settings))

    return true
  } catch (error) {
    console.log(error)
  }
}

const GetExam = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (settings === null || (settings.exam === undefined || settings.exam === null)) {
      return null
    }

    return settings.exam
  } catch (error) {
    console.log(error)
  }
}

const GetOptionFinishedOfExam = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (settings === null || (settings.exam === undefined || settings.exam === null)) {
      return null
    }

    if (settings.exam.finished === undefined || settings.exam.finished === null) {
      return null
    }

    const Data = {
      "finished" : settings.exam.finished,
      "dueDate" : settings.exam.dueDate,
      "completionDate" : settings.exam.completionDate
    }

    return Data
  } catch (error) {
    console.log(error)
  }
}

/**
 * Función para establecer el examen como finalizado
 * @returns boolean
 */
const SetFinishedExam = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (settings === null || (settings.exam === undefined || settings.exam === null)) {
      return null
    }

    settings.exam.finished = true
    //Agregar fecha de completado
    sessionStorage.setItem('settings',JSON.stringify(settings))

    return true
  } catch (error) {
    console.log(error)
  }
}





//Evaluator - Timer
/**
 * Función que devuelve una cadena con el número de segundos que restan para su examen
 * @returns string
 */
const GetExamTime = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (settings === null || (settings.exam === undefined || settings.exam === null)) {
      return null
    }

    if (settings.exam.time === undefined || settings.exam.time === null) {
      return null
    }

    return settings.exam.time
  } catch (error) {
    console.log(error)
  }
}

const GetDemoTime = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (settings === null || (settings.demo === undefined || settings.demo === null)) {
      return null
    }

    if (settings.demo.time === undefined || settings.demo.time === null) {
      return null
    }

    return settings.demo.time
  } catch (error) {
    console.log(error)
  }
}

const SetRemainingExamTime = (currentTime) => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (settings === null || (settings.exam === undefined || settings.exam === null)) {
      return null
    }

    //Actualizar tiempo del examen
    settings.exam.time = String(currentTime)
    sessionStorage.setItem('settings',JSON.stringify(settings))

    return true
  } catch (error) {
    console.log(error)
  }
}

const SetRemainingDemoTime = (currentTime) => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (settings === null || (settings.demo === undefined || settings.demo === null)) {
      return null
    }

    //Actualizar tiempo del examen
    settings.demo.time = String(currentTime)
    sessionStorage.setItem('settings',JSON.stringify(settings))

    return true
  } catch (error) {
    console.log(error)
  }
}

export { GetSettings, GetMenu, GetMessages, GetTheme, SetTheme, GetDemoExam, GetOptionFinishedOfDemoExam, SetFinishedDemoExam, GetExam, GetOptionFinishedOfExam, SetFinishedExam, GetExamTime, GetDemoTime, SetRemainingExamTime, SetRemainingDemoTime };