import { useEffect, useState } from 'react';

//Componentes comunes
import { Header } from './../../commons/components/Panel/Header';
// import { Menu } from './../../commons/components/Menu/Menu';
// import { MenuDown } from './../../commons/components/Menu/MenuDown';
import { Button } from './../../commons/components/Common/Button'

//Componente personalizado
import { Message } from './../../commons/components/Common/Message'

//Funciones para obtener datos
import { GetOptionFinishedOfDemoExam, GetOptionFinishedOfExam, GetTheme, SetTheme } from '../../utilities/BL/Settings';


const Dashboard = ({ beforeView, nextView, showDemoExam, showExam }) => {
    const [demoExamFinished, setDemoExamFinished] = useState(false)
    const [examFinished, setExamFinished] = useState(false)
    const [theTheme, setTheTheme] = useState('')
    
    const ShowDemoExamView = () => {
        try {
            showDemoExam()
            nextView()
        } catch (error) {
            console.log(error)
        }
    }

    const ShowExamView = () => {
        try {
            showExam()
            nextView()
        } catch (error) {
            console.log(error)
        }
    }

    const GetStatusOFExamns = () => {
        try {
            setDemoExamFinished(GetOptionFinishedOfDemoExam())
            setExamFinished(GetOptionFinishedOfExam())
        } catch (error) {
            console.log(error)
        }
    }



    const SetDefaultTheme = () => {
        try {
            const Theme = GetTheme()

            if (Theme.toLowerCase() === 'light') {
                document.body.classList.remove('DarkTheme')
                setTheTheme('light')
            } else if (Theme.toLowerCase() === 'dark') {
                document.body.classList.add('DarkTheme')
                setTheTheme('dark')
            }
        } catch (error) {
          console.log(error)
        }
    }

    const ChangeTheme = () => {
        try {
            if (theTheme.toLowerCase() === 'light') {
                document.body.classList.add('DarkTheme')
                setTheTheme('dark')
                SetTheme('dark')
            } else if (theTheme.toLowerCase() === 'dark') {
                document.body.classList.remove('DarkTheme')
                setTheTheme('light')
                SetTheme('light')
            }
        } catch (error) {
          console.log(error)
        }
    }



    useEffect(() => {
        SetDefaultTheme()
        GetStatusOFExamns()
    }, [])



    return (
        <>
            <Header schoolId={"ABCD-EFGH"} schoolName={"Escuela Normal de FDM"} beforeView={beforeView} theme={theTheme} changeTheme={ChangeTheme} />

            <Message />

            <main className='container Dashboard'>
                <h1 className='Dashboard-Title'>Hola Carlos</h1>
                
                {/* <p className='Dashboard-Legend'>En cuanto te lo agenden, aquí podrás encontrar el acceso a tu examen.</p> */}
                
                {demoExamFinished.finished ? (
                    <div className='Dashboard-Tiles'>
                        <p className='Dashboard-Tiles-Title'>Examen Demo</p>
                        <Button type={'button'} className={'Dashboard-BtnInactive'} iconButton={'bi bi-check-circle'} text={' Finalizado'} />
                        <p className='Dashboard-Tiles-Legend'>Finalizado el {demoExamFinished.completionDate}</p>
                    </div>
                ) : (
                    <div className='Dashboard-Tiles'>
                        <p className='Dashboard-Tiles-Title'>Examen Demo</p>
                        {/* <Button type={'button'} className={'Dashboard-BtnSecondary'} iconButton={'bi bi-file-earmark-text'} text={' Ir al examen Demo'} /> */}
                        <button type='button' className={'Dashboard-BtnSecondary'} onClick={() => ShowDemoExamView()}><i className='bi bi-file-earmark-text'></i> Ir al examen Demo</button>
                        <p className='Dashboard-Tiles-Legend'>Disponible hasta el {demoExamFinished.dueDate}</p>
                    </div>
                )}


                {examFinished.finished ? (
                    <div className='Dashboard-Tiles'>
                        <p className=''>Examen de ingreso para Preescolar</p>
                        {/* <Button type={'button'} className={'Dashboard-BtnInactive'} iconButton={'bi bi-lock'} text={' Ir al examen'} /> */}
                        <Button type={'button'} className={'Dashboard-BtnInactive'} iconButton={'bi bi-check-circle'} text={' Examen finalizado'} />
                        <p className='Dashboard-Tiles-Legend'>Finalizado el {examFinished.completionDate}</p>
                    </div>
                ) : (
                    <div className='Dashboard-Tiles'>
                        <p className=''>Examen de conocimientos</p>
                        {/* <Button type={'button'} className={'Dashboard-BtnSuccess'} iconButton={'bi bi-file-earmark-text'} text={' Ir al examen'} /> */}
                        <button type='button' className={'Dashboard-BtnSuccess'} onClick={() => ShowExamView()}><i className='bi bi-file-earmark-text'></i> Ir al examen</button>
                        <p className='Dashboard-Tiles-Legend'>Disponible hasta el {examFinished.dueDate}</p>
                    </div>
                )}
            </main>

            {/*
                <MenuDown />
                <Menu />
            */}
        </>
    )
}

export { Dashboard };