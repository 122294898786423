import { React, useState } from 'react'

// import { HelmetProvider } from 'react-helmet-async'

//--- Estilos e íconos de Bootstrap ---//
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import './styles/styles.css'
//--- Estilos e íconos de Bootstrap ---//


//--- Página Login ---//
import { Login } from './components/Login/Login'
//--- Página login ---//


//--- Página Dashboard ---//
import { Dashboard } from './components/Dashboard/Dashboard'
//--- Página Dashboard ---//


//--- Página Evaluator ---//
import { Evaluator } from './components/Evaluator/Evaluator'
//--- Página Evaluator ---//


//--- Página Evaluator ---//
import { Learning } from './components/Learning/Learning'
//--- Página Evaluator ---//


function App() {
  const [activeComponent, setActiveComponent] = useState('Login') // Estado para controlar el componente activo
  const [isDemo, setIsDemo] = useState(false)
  


  //--- Funciones para mostrar u ocultar los componentes principales ---//
  const ShowLoginView = () => {
    setActiveComponent('Login')
  }

  const ShowDashboardView = () => {
    setActiveComponent('Dashboard')
  }

  const ShowEvaluatorView = () => {
    setActiveComponent('Evaluator')
  }
  //--- Funciones para mostrar u ocultar los componentes principales ---//



  //--- Funciones para saber qué tipo de examen se va a mostrar ---//
  const ShowDemoExam = () => {
    setIsDemo(true)
  }

  const ShowExam = () => {
    setIsDemo(false)
  }
  //--- Funciones para saber qué tipo de examen se va a mostrar ---//



  return (
    <>
      {/* <HelmetProvider> */}
        {/* //--- Página login ---// */}
        {activeComponent === 'Login' && <Login nextView={ShowDashboardView} />}
        
        {/* //--- Página Dashboard ---// */}
        {activeComponent === 'Dashboard' && <Dashboard beforeView={ShowLoginView} nextView={ShowEvaluatorView} showDemoExam={ShowDemoExam} showExam={ShowExam} />}
        
        {/* //--- Página Evaluator ---//*/}
        {activeComponent === 'Evaluator' && <Evaluator beforeView={ShowDashboardView} isDemo={isDemo} />}
      {/* </HelmetProvider> */}
    </>


    //--- Página Material didáctico ---//
    // <Learning />
  )
}

export default App;