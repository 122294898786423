const Step = ({ current, total, maxVisibleItems = 3 }) => {
    try {
        //Función para opción 1 y 2
        const calculateProgress = () => {
            const progress = ((current) / total) * 100
            return Math.min(progress, 99.9) // Limitar a 99.9% para la última pregunta
        }

        //Función solo para opción 3
        const visibleItems = Math.min(total, maxVisibleItems) // Número de elementos a mostrar

        return (
            <>
                {/* Número y letras */}
                <p className='Evaluator-Steps-Text'>Pregunta {current} de {total}</p>

                {/* Barra de progreso */}
                <div className="progress Evaluator-Steps-Progressbar" role="progressbar" aria-label="Porcentaje de avance" aria-valuenow={calculateProgress()} aria-valuemin="0" aria-valuemax="100">
                    <div className="progress-bar progress-bar-striped bg-success" style={{ width: `${calculateProgress()}%` }}>{/* {Math.round(calculateProgress())}% */}</div>
                </div>

                {/* Pasos circulares */}
                <div className='Evaluator-Steps-Circle'>
                    {/* Renderizar los indicadores */}
                    {[...Array(visibleItems)].map((_, index) => (
                        <>
                            <span key={index} className={`Evaluator-Steps-Circle-Item ${index === current-1 ? 'Evaluator-Steps-Circle-Item-Active' : ''}`}>{index + 1}</span>
                            
                            {index < [...Array(visibleItems)].length-1 && (
                                <span key={'_' + index} className='Evaluator-Steps-Circle-Dash'></span>
                            )}
                        </>
                    ))}

                    {/* Mostrar puntos suspensivos si hay más preguntas que elementos visibles */}
                    {/* {total > visibleItems && <span>...</span>} */}
                </div>
            </>
        )
    } catch (error) {
        console.log(error)
    }
}

export { Step };